import styled from "styled-components";

import "./App.scss";

import { Imgs } from "./utils/imgManager";
import useWindowDimensions from "./utils/windowsize";

function App() {
	const { height, width } = useWindowDimensions();
	const hwRation = height / width;

	return (
		<Main>
			<Entropy>
				<img src={Imgs.Logo} alt={""} />
				<img src={Imgs.Entropyfi} alt={""} />
			</Entropy>

			{hwRation > 1.5 ? (
				<>
					{" "}
					<Title1 width={width} hwRation={hwRation}>
						<h1>The</h1>
						<h1>Lossless</h1>
						<h1>derivatives Platform</h1>
					</Title1>
					<Title2 width={width} hwRation={hwRation}>
						<span>
							Powering no-cost and capital-efficient risk management tools in
							amulti-chain world
						</span>
					</Title2>
				</>
			) : (
				<>
					<Title1 width={width} hwRation={hwRation}>
						<h1>The Lossless</h1>
						<h1>derivatives Platform</h1>
					</Title1>
					<Title2 width={width} hwRation={hwRation}>
						<span>Powering no-coast and capital-efficient risk</span>
						<span>management tools in amulti-chain world</span>
					</Title2>
				</>
			)}

			<ButtonGround width={width} hwRation={hwRation}>
				<StakingButton
					onClick={() =>
						window.open("https://entropyfi.vault.inc/#/", "_blank")
					}
				>
					<div>
						<span>Staking</span>
					</div>
				</StakingButton>
				<DAppButton
					onClick={() => window.open("https://app.entropyfi.com/ ", "_blank")}
				>
					<div>
						<span>dAPP</span>
					</div>
				</DAppButton>
			</ButtonGround>
			<Media width={width} hwRation={hwRation}>
				<img
					src={Imgs.gitbook}
					alt={""}
					onClick={() => window.open("https://docs.entropyfi.com/", "_blank")}
				/>
				<img
					src={Imgs.discard}
					alt={""}
					onClick={() =>
						window.open("https://discord.com/invite/TWErgkKSwy", "_blank")
					}
				/>
				<img
					src={Imgs.twitter}
					alt={""}
					onClick={() => window.open("https://twitter.com/entropyfi", "_blank")}
				/>
				<img
					src={Imgs.medium}
					alt={""}
					onClick={() => window.open("https://medium.com/entropyfi", "_blank")}
				/>
				<img
					src={Imgs.tg}
					alt={""}
					onClick={() =>
						window.open("https://t.me/joinchat/RDQoCbeThDNmNWY5", "_blank")
					}
				/>
			</Media>
		</Main>
	);
}

export default App;

const Main = styled.main`
	// background-image: linear-gradient(
	// 	89.87deg,
	// 	rgba(0, 0, 0, 0.4) 7.43%,
	// 	rgba(0, 0, 0, 0) 44.81%
	// );
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center center;
`;

const Entropy = styled.div`
	position: absolute;
	left: 5vw;
	top: 6.48vh;
	display: flex;
	align-content: center;
	align-items: center;
	> img {
		height: 2.59vh;
		&:first-of-type {
			margin-right: 2.5vh;
			height: 3.5vh;
		}
	}
`;

const Title1 = styled.div<{ width: number; hwRation: number }>`
	position: absolute;
	left: 5.21vw;
	top: ${(props) => (props.hwRation > 1.5 ? 20 : 24.81)}vh;
	height: 14.17vh;
	> h1 {
		font-family: "BR Hendrix";
		font-style: normal;
		font-weight: 600;
		// font-size: 6.6vh;
		font-size: ${(props) => (props.hwRation > 1.5 ? 9 : 6.6)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
		line-height: ${(props) => (props.hwRation > 1.5 ? 11.5 : 8.5)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
		color: #ffffff;
		cursor: default;
	}
`;
const Title2 = styled.div<{ width: number; hwRation: number }>`
	position: absolute;
	left: 5.21vw;
	right: ${(props) => (props.hwRation > 1.5 ? 6 : 0)}vw;
	top: ${(props) => (props.hwRation > 1.5 ? 42 : 45.3)}vh;
	> span {
		display: block;
		font-family: "BR Hendrix";
		font-style: normal;
		font-weight: 300;
		// font-size: 3vh;
		// line-height: 140%;
		font-size: ${(props) => (props.hwRation > 1.5 ? 6 : 3)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
		line-height: ${(props) => (props.hwRation > 1.5 ? 7 : 4.2)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
		color: #ffffff;
		cursor: default;
	}
`;
const ButtonGround = styled.div<{ width: number; hwRation: number }>`
	position: absolute;
	left: 5.21vw;
	top: 59.81vh;
	display: flex;
	width: ${(props) => (props.hwRation > 1.5 ? 82 : 57)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
	justify-content: space-between;
	> button {
		border-radius: 1vh;
		border: none;
		height: 6.11vh;
		width: ${(props) => (props.hwRation > 1.5 ? 39 : 27)}${(props) => (props.hwRation > 1.5 ? "vw" : "vh")};
		font-family: "BR Hendrix";
		font-style: normal;
		font-weight: 600;
		font-size: 2.8vh;
		line-height: 140%;

		cursor: pointer;

		&:hover {
			transform: scale(0.95);
		}
		> div {
			margin-top: 0.7vh;
		}
	}
`;
const StakingButton = styled.button`
	background: #746d59;
	color: white;
`;
const DAppButton = styled.button`
	background: #ffffff;
	color: #16131d;
`;

// const Main = styled.div<{ hwRation: number; height: number; width: number }>`

const Media = styled.div<{ width: number; hwRation: number }>`
	position: absolute;
	left: 5.21vw;
	top: ${(props) => (props.hwRation > 1.5 ? 83 : 89.07)}vh;
	display: flex;
	// width: 23.43vw;
	width: ${(props) =>
		props.hwRation > 0.8 ? 23.43 * (props.hwRation + 0.5) : 23.43}vw;
	justify-content: space-between;
	> img {
		height: 4.63vh;
		cursor: pointer;
		&:hover {
			transform: scale(0.95);
		}
	}
`;
