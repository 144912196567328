import Background from "../assets/svg/background.svg";
import Logo from "../assets/svg/logo_dynamic.svg";
import Entropyfi from "../assets/svg/Entropyfi.svg";

import discard from "../assets/svg/discard.svg";
import gitbook from "../assets/svg/gitbook.svg";
import medium from "../assets/svg/medium.svg";
import tg from "../assets/svg/tg.svg";
import twitter from "../assets/svg/twitter.svg";

export const Imgs = {
	Background,
	Logo,
	Entropyfi,
	discard,
	gitbook,
	medium,
	twitter,
	tg,
};
